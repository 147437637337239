<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Input Admin'" />
    <!-- <stepper-transaction :steps="steps" /> -->

    <div v-if="!this.$route.params.status">
      <v-divider class="my-6" />

      <h4 class="pb-4">Form Input</h4>
      <form-admin :formAdmin="formNewAdmin" />
      <button-form :submit="submitTransaction" class="pt-8" />
    </div>
    <!-- 
    <div v-if="this.$route.params.status">
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
      ></VueFileAgent>
      <button-form
        :submit="submitPayment"
        :buttonText="'Upload'"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Input</h4>
      <form-payment :formPayment="formNewPayment" />
      <button-form :submit="submitPayment" class="pt-8" />
    </div>-->

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperTransaction from "../../components/Stepper/StepperTransaction.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormAdmin from "../../components/Form/FormAdmin.vue";
import FormPayment from "../../components/Form/FormPayment.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperTransaction,
    ButtonForm,
    FormAdmin,
    FormPayment,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showSuccessDialog: false,
      showErrDialog: false,
      formNewAdmin: {
        admin_code: "",
        username: "",
        name: "",
        email: "",
        password: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  updated() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/admin");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async submitTransaction() {
      this.uploadedFile = "";
      try {
        console.log(this.formNewAdmin);
        await store.dispatch("admin/postAdmins", this.formNewAdmin);
        // this.steps = 1;
        this.showSuccessDialog = true;
        // this.$router.push("/admin");
      } catch (err) {
        this.showErrDialog = true;
      }
    },
  },
};
</script>
