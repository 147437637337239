<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!-- <v-text-field
      v-model="formData.pengguna"
      :rules="[(v) => !!v || 'Mohon isi pengguna']"
      label="Pengguna"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>-->

    <v-text-field
      v-model="formData.admin_code"
      :rules="[(v) => !!v || 'Mohon isi Kode Admin']"
      label="Kode Admin"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.username"
      :rules="[(v) => !!v || 'Mohon isi Username']"
      label="Username"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>
    <v-text-field
      v-model="formData.name"
      :rules="[(v) => !!v || 'Mohon isi Name']"
      label="Name"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>
    <v-text-field
      v-model="formData.email"
      :rules="[(v) => !!v || 'Mohon isi Email']"
      label="Email"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>
    <v-text-field
      v-model="formData.password"
      :rules="[(v) => !!v || 'Mohon isi Password']"
      label="Password"
      color="primary"
      outlined
      type="password"
      dense
      required
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    formAdmin: Object,
  },
  data: () => ({
    valid: true,
    formData: {
      admin_code: "",
      username: "",
      name: "",
      email: "",
      password: ""
    },
    statusKepemilikanRumahOptions: [
      {
        name: "Memiliki Rumah Sendiri",
        value: 1,
      },
      {
        name: "Tidak Memiliki Rumah Sendiri",
        value: 2,
      },
      {
        name: "Badan Hukum",
        value: 3,
      },
      {
        name: "Lainnya",
        value: 4,
      },
    ],
    reportOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 0,
      },
    ],
  }),

  mounted() {
    this.formData = this.formAdmin;

  },

};
</script>
